// extracted by mini-css-extract-plugin
export var ArtistDescription = "Fab5-module--ArtistDescription--CRBeS";
export var ArtistInfos = "Fab5-module--ArtistInfos--oKV98";
export var ButtonWrapper = "Fab5-module--ButtonWrapper --tmgco";
export var CardWrapper = "Fab5-module--CardWrapper--8nr15";
export var CarrouselWrapper2 = "Fab5-module--CarrouselWrapper2--gbcJc";
export var Citations = "Fab5-module--Citations--r9nJm";
export var DescriptionWrapper = "Fab5-module--DescriptionWrapper--gir4X";
export var ImageWrapper = "Fab5-module--ImageWrapper--IiWFL";
export var LinkWrapper = "Fab5-module--LinkWrapper--G2b7L";
export var MobileProtrait = "Fab5-module--MobileProtrait---sy9H";
export var More = "Fab5-module--More--l-PG1";
export var MoreButton = "Fab5-module--MoreButton--YtMa2";
export var NameWrapper = "Fab5-module--NameWrapper--9PyMy";
export var PdpWrapper = "Fab5-module--PdpWrapper---5w+z";
export var PhotosWrapper = "Fab5-module--PhotosWrapper--tra0G";
export var ProfilWrapper = "Fab5-module--ProfilWrapper--aqnTF";
export var TitleWrapper = "Fab5-module--TitleWrapper--Drb09";
export var Wrapper = "Fab5-module--Wrapper--wc2vZ";