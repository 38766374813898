
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Fab5.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import Fab5Photo1 from "../../../../res/Photos site/Fab 5/6.webp"
import Fab5Photo2 from "../../../../res/Photos site/Fab 5/Graff Fab 5.jpg"
import Fab5Photo3 from "../../../../res/Photos site/Fab 5/4.jpg"
import Fab5Photo4 from "../../../../res/Photos site/Fab 5/5.jpg"
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/fab5/portrait.jpg'
import PastPresent1 from "../../../../res/fab5/past-present-1.jpg"
import PastPresent2 from "../../../../res/fab5/past-present-2.jpg"
import PastPresent3 from "../../../../res/fab5/past-present-3.jpg"
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Fab5",
  name: "Fab5",
  description:
  "Fred Brathwaite, born August 31, 1959, better known as Fab 5 Freddy is an American visual artist, filmmaker and hip-hop pioneer. He is considered one of the founders of the graffiti movement. Freddy emerged on the downtown New York underground creative scene in the late 1970s as a graffiti artist. He was the link between the booming uptown rap scene and the downtown No Wave art scene. In the late 1970s, Freddy became a member of Brooklyn-based graffiti group The Fabulous 5, known for painting whole New York City subway trains. Together with fellow Fabulous 5 member Lee Quiñones they began to move from street graffiti and then transition into the more conventional art world and in 1979 they both exhibited at a prestigious Italian gallery in Rome the Galleria La Medusa. In 1980, he painted a subway train with cartoon-style depictions of giant Campbell's soup cans as a nod to contemporary artist Andy Warhol. He appeared in the 1981 Rapture music video when Debbie Harry rapped that \"Fab 5 Freddy told me everybody steals.\" He had also starred in the fiction Wild style alongside Lady Pink and Lee Quinones representing the lives of these young graffiti artists. In the late 1980s, Freddy became the first host of the groundbreaking hip-hop music video show Yo! MTV Raps.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Fab5.",
  citations: [
    "« People actually perceived me with being this cat from the Bronx because I\'m one of a handful of folks that was actually acting in 'Wild Style’ »"
  ],
  photos: [
    { src: PastPresent1, name: "Fab5" },
    { src: PastPresent2, name: "Fab5" },
    { src: PastPresent3, name: "Fab5" },
  ],
};
const Fab5 = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>Fab 5 Freddy <h2 style={{paddingLeft: "16px"}}>(Fred Brathwaite - American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1959</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default Fab5;